<!-- eslint-disable no-restricted-syntax -->
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bem vindo ao AdeConnect! 👋
          </b-card-title>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Logon"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="operator"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Entrar
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
// eslint-disable-next-line camelcase
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.checkLogonStatus()
  },
  methods: {
    checkLogonStatus() {
      if (localStorage.getItem('accessToken') === null || localStorage.getItem('accessToken') === 'undefined') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Efetuar logon',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.$router.push('/')
        throw new Error('No token, logon in the system')
      } else {
        this.$router.push('/')
      }
    },
    async getUserFromKeyCloak() {
      await axios
        .post('/auth/authenticate', {
          username: this.userEmail,
          user_password: this.password,
        })
        .then(response => {
          const extractedToken = response.data
          const companies = []
          let ability = ''

          const extCompanies = extractedToken.groups

          // Removing default roles
          for (let i = 0; i < extCompanies.length; i += 1) {
            if (extCompanies[i].length === 14 && !extCompanies[i].includes('offline_access')) {
              companies.push(extCompanies[i])
            }
            if (extCompanies[i] === 'client_admin' || extCompanies[i] === 'operator' || extCompanies[i] === 'admin') {
              ability = extCompanies[i]
            }
          }
          const userData = {
            userData: {
              id: extractedToken.sid,
              fullName: extractedToken.name,
              username: extractedToken.preferred_username,
              avatar: '/img/13-small.d796bffd.png',
              email: extractedToken.email,
              cnpj: companies,
              group: ability,
              kcgroups: extractedToken.groups,
            },
            accessToken: extractedToken.access_token,
            refreshToken: extractedToken.refresh_token,
          }

          localStorage.setItem('userData', JSON.stringify(userData.userData))
          localStorage.setItem('accessToken', extractedToken.access_token)
          localStorage.setItem('refreshToken', extractedToken.refresh_token)
          // this.$ability.update(userData.userData.ability)

          return userData
        })
        .then(response => {
          console.log(response)
          this.$router.push('/home')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Logon succeeded',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Logon denied',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
          console.log(err)
        })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.getUserFromKeyCloak()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
